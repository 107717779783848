import './App.css';
import { SHA256 } from 'crypto-js';
import { useState,useEffect } from 'react';

function App() {
  const [value, setValue] = useState("");

  async function compute() {
    console.log(value);
    document.getElementById("img").src = await HashImage(value);
  }

  async function changeValue(v) {
    setValue(v.target.value)
  } 

  const HashImage = async (value) => {
    const inputString = value;
    var hash = SHA256(inputString).toString();
    var og = hash;

    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext('2d');


    for(let i=0; i<256*256; i++) {
      let x = i % 256;
      let y = Math.floor(i / 256);
      ctx.fillStyle = `#${hash.substring(0, 6)}`;
      hash = hash.substring(6);
      if(hash.length <6) {
        hash = SHA256(og).toString();
        og = hash;
      }
      ctx.fillRect(x*1, y*1, 1, 1);
    }

    return canvas.toDataURL();
  }

  useEffect(() => {(async () => compute())()}, []);

    return (
      <div className="App text-center p-5">
        <p className='mt-5 font-bold'>BAD AI</p>
        <p>A generative ai model that sucks!</p>
        <input className='mt-3' value={value} placeholder='prompt' onChange={changeValue}></input>
        <br></br>
        <button className='mt-1 text-blue-400 hover:text-blue-700' onClick={compute}>generate image</button>
        <br></br>
        <br></br>
        <img id="img" className='drop-shadow-1xl'></img>
        <br></br><br></br>
        <p className="imptext">The vast expanse of AI models is virtually limitless, with an infinite number of possibilities. Training an AI model is simply a more efficient method of discovering a valuable model. When we come across art from models such as DALL-E or Stable Diffusion, are we committing an act of theft or are we simply uncovering intriguing outcomes from the endless realm of AI models?
        </p>
        <p></p>
        <br></br>
        <table>
          <tr>
            <td><a className='text-blue-400 hover:text-blue-700' target="_blank" href="https://twitter.com/notpinkxyz/status/1617209096826155008">share your thoughts on twitter</a></td>
          </tr>
        </table>
        <br></br>
        <p className="imptext">Our generative ai sucks, does this mean we aren't stealing art?</p>
        <br></br>
        <hr></hr>
        <br></br>
        <p className="imptext">The probability of discovering these images using our AI is incredibly low, at 1/(2^6291456) but not 0. Does this mean that our model is engaging in theft? If not, at what threshold of probability would the use of our model be considered as theft?</p>

        <div className='p-5 show flex flex-row flex-wrap'>
          <img src="martin-luther-king-speeches-gettyimages-517320132.jpg" className='showcase m-3'></img>
          <img src="Beatles_-_Abbey_Road.jpg" className='showcase m-3'></img>
          <img src="Canyon_River_Tree_(165872763).jpeg" className='showcase m-3'></img>
          <img src="g_wonderfulworldofmickeymouseshorts_photo01_20114_a687e740.jpeg" className='showcase m-3'></img>
          <img src="MPSS_Mario.webp" className='showcase m-3'></img>
          <img src="Screenshot 2023-01-22 172658.png" className='showcase m-3'></img>
          <img src="Screenshot 2023-01-22 172741.png" className='showcase m-3'></img>
        </div>
        <hr></hr>
        <br></br>
        <p className='imptext'>an archive for future internet citizens by <a target="_blank" href="https://twitter.com/notpinkxyz" className='text-blue-400 hover:text-blue-700'>notpink</a></p>
      </div>
    );
  }

  export default App;
